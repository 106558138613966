import React from 'react'
import loadable from '@loadable/component'
import {
  secondaryPagesHeader,
  benefits, Cover, Insurances, fullParagraphContent, paragraphImage, faqInformations, benefitsList
} from '../utils/informations';

import { findByClaimsAndMonth, groupBy } from '../utils/custom-functions'
import { useStaticQuery, graphql } from 'gatsby'
import CoveredList from '../components/coveredList/coveredList';
import FullScreenParagraph from '../components/fullscreen-paragraph/fullscreen-paragraph';
import HomeInsurances from '../components/home-insurances/home-insurances';
import ParagraphImage from '../components/paragraph-image/paragraph-image';
import Faq from '../components/faq-v2/faq';
import BenefitsList from '../components/benefits-list/benefitsList';
import GapInsuranceCard from '../components/gap-insurance-card/gap-insurance-card';
import 'uikit/dist/css/uikit.css'

const Layout = loadable(() => import('../layouts'))
const Header = loadable(() => import('../components/header/header'))

//const secondaryPagesHeader = loadable(() => import('../utils/informations'))
const AlloyWheel = loadable(() => import("../components/alloy-wheel/alloy-wheel"));


const Helmet = loadable(() => import('react-helmet'))

const ProductsPage = ({ pageContext }) => {
  const { site } = useStaticQuery(
    graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
              }
            }
          }
        `
  )
  const { filtered, origin, price } = pageContext


  const allUniqueResults = findByClaimsAndMonth(filtered, [100, 125, 200, 250, 500, 750, 1000, 1500, 2500, 5000, 10000, 15000, 20000, 25000, 30000, 40000, 50000], [12, 24, 36, 48, 60])

  const filterUndefined = allUniqueResults.filter((item) => item !== undefined)


  const groupByClaim = groupBy('claim_limit', filterUndefined)
  const groupValues = Object.values(groupByClaim)

  return (
    <Layout>
      <Helmet>
        <title>{origin === 'MBI' ? 'Vehicle' : origin} Insurance - {site.siteMetadata.title}</title>

        
        
      </Helmet>
      <Header props={secondaryPagesHeader} origin={origin} price={price} />
      <HomeInsurances />
      <ParagraphImage props={paragraphImage} origin={origin} />
      <FullScreenParagraph props={fullParagraphContent} origin={origin} />
      <CoveredList props={Cover} origin={origin} />
      <GapInsuranceCard props={Insurances} origin={origin} />

      <Faq props={faqInformations} origin={origin} />
      <BenefitsList props={benefitsList} origin={origin} />
      <AlloyWheel props={benefits} origin={origin} />

    </Layout>
  )
}

export default ProductsPage
