import React from "react";
import "./benefitsList.scss";
import checked_icon from "../../assets/checkedIconGreen.webp";
import { StaticImage } from "gatsby-plugin-image"
import { checkTheme } from "../../utils/informations"


const BenefitsList = ({ props, origin }) => {

    return (

        props.filter((item) => item.origin === origin).map((obj, index) => {
            const { title, description, benefits } = obj;
            return (

                <div className="benefit-container" key={index}>

                    <div className="benefit-content">


                        <div className="benefit-title">
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </div>


                        <div className="benefit-list">


                            {
                                benefits ? benefits.map((benefit, index) => {
                                    return (
                                        <div key={index} className="benefit-flex">
                                            <img src={checked_icon} alt="product-benefits"></img>
                                            <span>{benefit}</span>
                                        </div>
                                    )
                                }) : ""
                            }


                        </div>



                    </div>

                    <div className={checkTheme().pageTitle.includes("Invictus") || checkTheme().pageTitle.includes("Alternative") ? "display-no-piggies" : ""}>
                        <StaticImage src='../../assets/pink_flipped_cut.png' alt="pig_icon" className="pink-cut-benefit" />
                    </div>
                </div>

            )
        })



    );
};

export default BenefitsList;
