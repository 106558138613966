import React, { useEffect, useRef } from "react"
import { Link } from 'gatsby'

import './home-insurances.scss';


import { StaticImage } from "gatsby-plugin-image"

import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";

import { originExist, weddingActivate } from '../../utils/informations'



const HomeInsurances = () => {

    return (
        <>
            <div className="home-insurances-container">
                <div className="home-insurances-content">
                    <div className="prev-btn min-width align-svg" onClick={() => document.getElementById('previous-btn-slider').click()}>
                        <AiOutlineDoubleLeft />
                    </div>
                    <div className="uk-position-relative uk-visible-toggle uk-light home-slider-container" tabIndex="-1" uk-slider="true">
                        <ul className="uk-slider-items uk-child-width-1-1@xs uk-child-width-1-3@s uk-child-width-1-6@m slider-insurances">
                            {
                                originExist("MBI") ?

                                    <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/vehicle-warranty-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                        <StaticImage src='../../assets/warranty.svg' alt="warranty" />
                                                    </div>
                                                </div>
                                                <p>Vehicle</p>
                                                <span>Warranty</span>
                                            </Link>
                                        </div>
                                    </li>
                                    : ""
                            }
                            {
                                originExist("Return to Invoice") ?
                                    <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/gap-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                        <StaticImage src='../../assets/gap.svg' alt="warranty" />
                                                    </div>
                                                </div>
                                                <p>Gap</p>
                                                <span>Insurance</span>
                                            </Link>

                                        </div>
                                    </li>
                                    : ""
                            }
                            {
                                originExist("Alloy Wheel") ?
                                    <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/alloy-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                        <StaticImage src='../../assets/alloy.svg' alt="warranty" />
                                                    </div>
                                                </div>
                                                <p>Alloy Wheel</p>
                                                <span>Insurance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    : ""
                            }
                            {
                                originExist("Cosmetic Repair") ?
                                    <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/cosmetic-repair-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                        <StaticImage src='../../assets/cosmetic.svg' alt="warranty" />
                                                    </div>
                                                </div>
                                                <p>Cosmetic </p>
                                                <span>Repair Insurance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    : ""
                            }
                            {
                                originExist("Tyre") ?
                                    <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/tyre-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                        <StaticImage src='../../assets/tyre.svg' alt="warranty" />
                                                    </div>
                                                </div>
                                                <p>Tyre </p>
                                                <span>Insurance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    : ""
                            }
                            {
                                originExist("Excess") ?
                                    <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/excess-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                        <StaticImage src='../../assets/excess.svg' alt="warranty" />
                                                    </div>
                                                </div>
                                                <p>Excess </p>
                                                <span>Insurance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    : ""
                            }
                            {
                                weddingActivate() == true ? 
                                <li className="insurance-item">
                                        <div className="insurance-item-content">
                                            <Link to="/wedding-insurance">
                                                <div className="home-margin-img">
                                                    <div className="insurance-image">
                                                    <StaticImage src='../../assets/weddingIcon.png' alt="alloy" />
                                                    </div>
                                                </div>
                                                <p>Wedding </p>
                                                <span>Insurance</span>
                                            </Link>
                                        </div>
                                    </li>
                                    : ""
                            }
                        </ul>

                        <a className="uk-position-center-left " href="#" id="previous-btn-slider" uk-slidenav-previous="true" uk-slider-item="previous" style={{ visibility: 'hidden' }}></a>
                        <a className="uk-position-center-right " href="#" id="next-btn-slider" uk-slidenav-next="true" uk-slider-item="next" style={{ visibility: 'hidden' }}></a>

                    </div>
                    <div className="next-btn min-width align-svg" onClick={() => document.getElementById('next-btn-slider').click()}>
                        <AiOutlineDoubleRight />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeInsurances